import {HeaderBackgroundColorScheme} from '@/enums';
import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import Header from '@/components/brochureV2/section/Header/Header';

const FinancialServicesHeader = (props?: HeaderProps, pageTitle?: string) => {
  const {background, ...restProps} = props || {};
  const backgroundColorScheme =
    props?.background || HeaderBackgroundColorScheme.TransparentLight;

  return (
    <Header
      {...restProps}
      background={backgroundColorScheme}
      secondaryNavFn={({t, localizePath}) => {
        const links = [
          {
            text: t('nav:balance'),
            url: localizePath('/balance'),
            name: 'balance',
          },
          {
            text: t('nav:capital'),
            name: 'capital',
            links: [
              {
                text: t('nav:capitalSmallBusiness'),
                url: localizePath('/capital'),
                name: 'borrowing',
              },
              {
                text: t('nav:capitalLoansAndCashAdvances'),
                url: localizePath('/capital-loans-and-cash-advances'),
                name: 'capital-loans-and-cash-advances',
              },
            ],
          },
          {
            text: t('nav:credit'),
            url: localizePath('/credit'),
            name: 'credit',
          },
          {
            text: t('nav:billPay'),
            url: localizePath('/bill-pay'),
            name: 'bill-pay',
          },
        ];

        return {
          sectionTitle: t('nav:financialServices'),
          sectionOverviewUrl: null,
          pageTitle: t(`nav:${pageTitle}`),
          links,
        };
      }}
    />
  );
};

export default FinancialServicesHeader;
